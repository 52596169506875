/* eslint-disable import/no-duplicates */
import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LightTheme } from '@shuttlerock/mui-components';
import { Routes } from '@shuttlerock/router';
import backgroundPng from '../../assets/GradientBackground.png';
import backgroundWebp from '../../assets/GradientBackground.png?format=webp';
import ShuttlerockLogo from '../../assets/ShuttlerockLogo.svg';
import { GlitchText } from './GlitchText';

export interface ErrorPageProps {
  title: string;
  body: string;
  primaryButton?: {
    onClick?: () => void;
    text: string;
    route?: string;
  };
  secondaryButton?: {
    text: string;
    route: string;
  };
}

export const ErrorPage = ({
  body,
  title,
  primaryButton,
  secondaryButton,
}: PropsWithChildren<ErrorPageProps>) => {
  const getRouteProps = (route: string) => {
    return route.startsWith('http')
      ? { component: 'a' as const, href: route }
      : { component: Link, to: route };
  };

  return (
    // Force LightTheme as this page is used universally but uses light theme's colors
    <ThemeProvider theme={LightTheme}>
      <Stack
        sx={{
          backgroundImage: `url(${backgroundPng})`,
          [`@supports (background-image: url('${backgroundWebp}'))`]: {
            backgroundImage: `url(${backgroundWebp})`,
          },
          backgroundSize: 'cover',
          height: '100%',
        }}
      >
        <Stack
          sx={{ width: '100%', backgroundColor: ['grey.A50', 'transparent'] }}
          alignItems={['center', 'flex-start']}
          pl={[null, 5]}
          py={[2, 3]}
        >
          <Box
            sx={{
              color: 'text.primary',
              width: '240px',
              path: {
                fill: [null, '#fff'], // Change logo to full white
              },
            }}
            component={Link}
            to={Routes.Home}
          >
            <ShuttlerockLogo
              data-testid="ShuttlerockLogo"
              style={{ display: 'block' }}
            />
          </Box>
        </Stack>
        <Stack sx={{ flex: 1 }} justifyContent="center" alignItems="center">
          <Box sx={{ maxWidth: '650px' }} px={3}>
            <GlitchText
              variant="xxl"
              variantMapping={{ xxl: 'h1' }}
              color="primary.contrastText"
              mb={3}
            >
              {title}
            </GlitchText>
            <Typography
              variant="subtitle1"
              component="p"
              color="primary.contrastText"
              mb={9}
            >
              {body}
            </Typography>
            <Stack direction={['column', 'row']} sx={{ gap: 2 }}>
              {primaryButton ? (
                <Button
                  variant="contained"
                  onClick={primaryButton?.onClick}
                  {...(primaryButton?.route
                    ? getRouteProps(primaryButton.route)
                    : {})}
                >
                  {primaryButton?.text}
                </Button>
              ) : null}
              {secondaryButton ? (
                <Button
                  variant="contained"
                  color="secondary"
                  {...getRouteProps(secondaryButton?.route)}
                >
                  {secondaryButton?.text}
                </Button>
              ) : null}
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
