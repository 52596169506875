/* eslint-disable prefer-arrow-callback */
import { Children, ElementType, forwardRef, ReactElement, Ref } from 'react';
import { ButtonBase, styled, SxProps, Theme } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

export type SrIconButtonOwnProps = {
  variant?: 'outlined' | 'text' | 'checkbox';
  color?: 'default' | 'primary';
  size?: 'small' | 'medium' | 'large' | 'xl';
  shape?: 'circle' | 'square';
  // TODO: If this is used again, we may need a SrCheckbox component?
  checked?: boolean;
  sx?: SxProps<Theme>;
};

export interface SrIconButtonTypeMap<
  // eslint-disable-next-line @typescript-eslint/ban-types
  AdditionalProps = {},
  RootComponent extends ElementType = typeof ButtonBase,
> {
  props: AdditionalProps & SrIconButtonOwnProps;
  defaultComponent: RootComponent;
}

export type SrIconButtonProps<
  D extends ElementType = SrIconButtonTypeMap['defaultComponent'],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {},
> = OverrideProps<SrIconButtonTypeMap<P, D>, D>;

export const srIconButtonClasses = {
  outlined: 'SrIconButton-outlined',
  text: 'SrIconButton-text',
  checkbox: 'SrIconButton-checkbox',

  primary: 'SrIconButton-primary',
  default: 'SrIconButton-default',

  small: 'SrIconButton-small',
  medium: 'SrIconButton-medium',
  large: 'SrIconButton-large',
  xl: 'SrIconButton-xl',

  circle: 'SrIconButton-circle',
  square: 'SrIconButton-square',

  checked: 'SrIconButton-checked',

  label: 'SrIconButton-label',
} as const;

const SrIconButtonRoot = styled(ButtonBase)(({ theme }) => {
  return {
    gap: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    letterSpacing: 0.46,
    lineHeight: '20px',
    fontWeight: 600,

    [`&.${srIconButtonClasses.small}`]: {
      height: 30,
      minWidth: 30,
      svg: { fontSize: 12 },
      [`&.${srIconButtonClasses.label}`]: {
        padding: `0 ${theme.spacing(1)}`,
      },
    },

    [`&.${srIconButtonClasses.medium}`]: {
      height: 36,
      minWidth: 36,
      svg: { fontSize: 14 },
      [`&.${srIconButtonClasses.label}`]: {
        padding: `0 ${theme.spacing(1.5)}`,
      },
    },

    [`&.${srIconButtonClasses.large}`]: {
      height: 42,
      minWidth: 42,
      svg: { fontSize: 16 },
      [`&.${srIconButtonClasses.label}`]: {
        padding: `0 ${theme.spacing(2)}`,
      },
    },

    [`&.${srIconButtonClasses.xl}`]: {
      height: 48,
      minWidth: 48,
      fontSize: 18,
      svg: { fontSize: 24 },
      [`&.${srIconButtonClasses.label}`]: {
        padding: `0 ${theme.spacing(2)}`,
      },
    },

    [`&.${srIconButtonClasses.circle}`]: {
      borderRadius: '24px',
    },

    [`&.${srIconButtonClasses.square}`]: {
      borderRadius: theme.shape.borderRadius,
      [`&.${srIconButtonClasses.small}`]: {
        borderRadius: theme.shape.borderRadius / 2,
      },
    },

    [`&.${srIconButtonClasses.text}`]: {
      [`&.${srIconButtonClasses.default}`]: {
        color: theme.palette.text.primaryAlt,
      },
      [`&.${srIconButtonClasses.primary}`]: {
        color: theme.palette.stroke.active,
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },

    [`&.${srIconButtonClasses.outlined}`]: {
      border: '1px solid',
      [`&.${srIconButtonClasses.default}`]: {
        color: theme.palette.text.secondary,
        borderColor: theme.palette.stroke.dark,
        '&:hover': {
          borderColor: theme.palette.stroke.hover,
          backgroundColor: theme.palette.foreground.tertiary,
        },
        '&:active': {
          color: theme.palette.text.primaryAlt,
        },
      },
      [`&.${srIconButtonClasses.primary}`]: {
        color: theme.palette.stroke.active,
        '&:hover:not(:active)': {
          backgroundColor: theme.palette.foreground.tertiary,
        },
      },
    },

    [`&.${srIconButtonClasses.checkbox}:not(${srIconButtonClasses.checked})`]: {
      color: theme.palette.text.secondary,
      '&:hover': { color: theme.palette.text.primaryAlt },
    },

    [`&.${srIconButtonClasses.checkbox}.${srIconButtonClasses.checked}`]: {
      color: theme.palette.primary.light,
      '&:hover': { color: theme.palette.primary.dark },
    },
  };
});

export const SrIconButton = forwardRef(function IconButton<
  D extends ElementType,
>(
  {
    variant = 'text',
    size = 'medium',
    color = 'default',
    shape = 'square',
    checked = false,
    disabled,
    className,
    children,
    ...rest
  }: SrIconButtonProps<D>,
  ref: Ref<D>,
) {
  const classNames = [
    srIconButtonClasses[variant] ?? srIconButtonClasses.text,
    srIconButtonClasses[color] ?? srIconButtonClasses.default,
    srIconButtonClasses[size] ?? srIconButtonClasses.medium,
    srIconButtonClasses[shape] ?? srIconButtonClasses.square,
    checked ? srIconButtonClasses.checked : undefined,
    Children.toArray(children).filter(Boolean).length > 1 &&
      srIconButtonClasses.label,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <SrIconButtonRoot
      ref={ref as Ref<HTMLButtonElement>}
      className={classNames}
      disabled={disabled}
      {...rest}
    >
      {children}
    </SrIconButtonRoot>
  );
}) as unknown as <D extends ElementType>(
  props: SrIconButtonProps<D>,
) => ReactElement;
