/* eslint-disable react/destructuring-assignment */
import { ReactElement } from 'react';
import i18next, {
  i18n,
  InitOptions,
  type ParseKeys,
  Resource,
  type TOptions,
} from 'i18next';
import {
  Trans as TransBase,
  TransProps,
  useTranslation as useTranslationBase,
  UseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';
import { DefaultNamespace, KeyPrefix, Namespace } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { captureWarning } from '@shuttlerock/observability';
import * as React from 'react';

const missingKeyHandler = (
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string,
): void => {
  const message = `Translation for (${lngs.join(
    ',',
  )}).${ns}.[${key}] not found, [${fallbackValue}] will be used`;

  captureWarning(new Error(message));
};

export const defaultOptions: InitOptions = {
  fallbackLng: 'en', // Language to use if not found
  nsSeparator: '|', // Delimiter separating namespace from key
  ...(process.env.CI ? { lang: 'en' } : {}),
  keySeparator: ':',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  saveMissing: true,
  missingKeyHandler,
  debug: process.env.DEBUG_I18N === 'true', // logs info level to console output. Helps to find issues with loading not working. This does not prevent the console logging of missing translations
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lang', // Means you can add `?lang=ja` to enforce language
    lookupCookie: 'i18next', // If we want to save selected language
    lookupLocalStorage: 'i18nextLng', // If we want to save selected language
    lookupFromPathIndex: 0, // from which position in path to look for language, e.g. cloud.shuttlerock.com/en
    lookupFromSubdomainIndex: 0, // from which position in subdomain to look for language, e.g. en.cloud.shuttlerock.com

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  },
};

export const createInstance = (resources: Resource, defaultNS?: string) => {
  const instance: i18n = i18next
    .createInstance({ ...defaultOptions, resources, defaultNS })
    .use(LanguageDetector);

  function useTranslation<
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined,
  >(
    ns?: N | Readonly<N>,
    options?: UseTranslationOptions<TKPrefix>,
  ): UseTranslationResponse<N, TKPrefix> {
    const base = useTranslationBase(ns || defaultNS, {
      ...options,
      i18n: options?.i18n || instance,
    });
    // Not sure if this is a bug with i18next?
    base.i18n.setDefaultNamespace(`${ns || defaultNS}`);
    return base;
  }

  const Trans = <
    Key extends ParseKeys<Ns, TOpt, KPrefix>,
    Ns extends Namespace = DefaultNamespace,
    KPrefix = undefined,
    TContext extends string | undefined = undefined,
    TOpt extends TOptions<Record<string, unknown>> & { context?: TContext } = {
      context: TContext;
    },
    E = React.HTMLProps<HTMLDivElement>,
  >(
    props: TransProps<Key, Ns, KPrefix, TContext, TOpt, E>,
  ): ReactElement => {
    return (
      <TransBase
        {...props}
        ns={props?.ns || defaultNS}
        i18n={props?.i18n || instance}
      />
    );
  };

  return {
    i18n: instance,
    Trans,
    useTranslation,
  };
};
